import { Controller } from "@hotwired/stimulus"
// import { soundManager } from "soundmanager2"

export default class extends Controller {
  connect() {
    soundManager.setup({
      debugFlash: false,
      debugMode: false,
      url: "/swf/",
      onready: () => {
        this.yeehaw = soundManager.createSound({
          url: "/yeehaw.wav",
          autoload: true,
        })
      },
    })
  }

  click() {
    this.yeehaw.play()
  }
}
