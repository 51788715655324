import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { id: String }

  connect() {
    this.subscribeToMachineChannel()
  }

  subscribeToMachineChannel() {
    this.subscription = createConsumer().subscriptions.create(
      { channel: "Player::MachineChannel", id: this.idValue },
      {
        received: (data) => {
          if (data.action === "reload") {
            window.location.reload()
          }
        },
      }
    )
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
