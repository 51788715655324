import { Controller } from "@hotwired/stimulus"

const checkedClass = "is-checked"

export default class extends Controller {
  connect() {
    const labels = Array.from(this.element.querySelectorAll("label"))
    const defaultState = this.element.dataset.defaultState === "true"
    const noneChecked =
      !labels.some((label) => label.querySelector("input").checked) &&
      defaultState
    labels.forEach((label, index) => {
      const input = label.querySelector("input")
      if (input.checked) {
        label.classList.add(checkedClass)
      } else if (noneChecked && index === 0) {
        input.checked = true
        label.classList.add(checkedClass)
      }
    })
  }

  click(event) {
    const label = event.target.closest("label")
    Array.from(this.element.children).forEach((element) => {
      if (element === label) {
        element.classList.add(checkedClass)
      } else {
        element.classList.remove(checkedClass)
      }
    })
    label.querySelector("input").checked = true
  }
}
