import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.navigateOnChange = this.navigateOnChange.bind(this)
    this.element.addEventListener("change", this.navigateOnChange)
  }

  navigateOnChange(event) {
    window.location.href = event.target.value
  }

  disconnect() {
    this.element.removeEventListener("change", this.navigateOnChange)
  }
}
