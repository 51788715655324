import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.mask = IMask(this.inputTarget, {
      mask: /^[A-Za-z0-9]{0,6}$/,
      prepare: (str) => str.toUpperCase(),
    })
    this.mask.on(
      "accept",
      () => (this.buttonTarget.disabled = this.inputTarget.value.length !== 6)
    )
  }

  disconnect() {
    this.mask?.destroy()
  }
}
