import "./style.css"

import "@hotwired/turbo-rails"
Turbo.start()

import { Application } from "@hotwired/stimulus"
import Dropdown from "@stimulus-components/dropdown"
import { registerControllers } from "stimulus-vite-helpers"
const application = Application.start()
application.register("dropdown", Dropdown)
application.debug = true
window.Stimulus = application
const controllers = import.meta.glob("../controllers/**/*_controller.js", {
  eager: true,
})
registerControllers(application, controllers)
