import { Controller } from "@hotwired/stimulus"
import { toggle, leave } from "el-transition"

export default class extends Controller {
  static targets = ["button", "dropdown", "mobileMenu", "mobileButton"]

  connect() {
    this.closeDropdownOnEscape = this.closeDropdownOnEscape.bind(this)
    window.addEventListener("keydown", this.closeDropdownOnEscape)
  }

  click() {
    const dropdown = this.dropdownTarget
    toggle(dropdown, "dropdown")
  }

  toggleMobile() {
    const mobileMenu = this.mobileMenuTarget
    const mobileOpen = this.mobileButtonTarget.querySelector("i:not(.hidden)")
    const mobileClose = this.mobileButtonTarget.querySelector("i.hidden")
    toggle(mobileMenu, "dropdown")
    mobileOpen.classList.add("hidden")
    mobileClose.classList.remove("hidden")
  }

  closeDropdownOnEscape(event) {
    if (event.key === "Escape") {
      event.preventDefault()
      const dropdown = this.dropdownTarget
      leave(dropdown, "dropdown")
    }
  }

  disconnect() {
    window.removeEventListener("keydown", this.closeDropdownOnEscape)
  }
}
