import { Controller } from "@hotwired/stimulus"
import { toggle } from "el-transition"

export default class extends Controller {
  click(event) {
    if (event.target.closest("a")) return

    const rowSelector = event.target.closest("tr").dataset.collapseSelector
    const elements = this.element.querySelectorAll(
      `[data-collapse-target="${rowSelector}"]`
    )
    elements.forEach((element) => {
      toggle(element)
    })
  }
}
