import { Controller } from "@hotwired/stimulus"

const TRANSLATE_CLASS = "translate-x-0"
const OPACITY_CLASS = "opacity-100"

export default class extends Controller {
  static targets = ["backdrop", "sidebar"]

  connect() {
    this.closeSidebarOnEscape = this.closeSidebarOnEscape.bind(this)
    window.addEventListener("keydown", this.closeSidebarOnEscape)
  }

  click(event) {
    event.preventDefault()
    this.sidebarTarget.classList.toggle(TRANSLATE_CLASS)
    this.backdropTarget.classList.toggle(TRANSLATE_CLASS)
    this.backdropTarget.classList.toggle(OPACITY_CLASS)
  }

  closeSidebarOnEscape(event) {
    if (event.key === "Escape") {
      event.preventDefault()
      this.sidebarTarget.classList.remove(TRANSLATE_CLASS)
      this.backdropTarget.classList.remove(TRANSLATE_CLASS)
      this.backdropTarget.classList.remove(OPACITY_CLASS)
    }
  }

  disconnect() {
    window.removeEventListener("keydown", this.closeSidebarOnEscape)
  }
}
