import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.mask = IMask(this.inputTarget, { mask: "000000" })
    this.mask.on(
      "accept",
      () => (this.buttonTarget.disabled = !this.mask.masked.isComplete)
    )
  }

  disconnect() {
    this.mask?.destroy()
  }
}
