import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.mask = IMask(this.inputTarget, {
      mask: Number,
      scale: 0,
      thousandsSeparator: ",",
      min: 0,
      max: 9007199254740991,
    })
  }

  disconnect() {
    this.mask?.destroy()
  }
}
